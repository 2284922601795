<template>
  <component :is="currentBlog" v-if="currentBlog" />
  <div v-else>
    Loading...
  </div>
</template>

<script>
// 预先导入所有博客组件
const blogComponents = {
  shakespeare: {
    1: () => import('./shakespeare/blog-1.vue'),
    2: () => import('./shakespeare/blog-2.vue'),
    3: () => import('./shakespeare/blog-3.vue'),
    4: () => import('./shakespeare/blog-4.vue')
  },
  annakarenina: {
    1: () => import('./annakarenina/blog-1.vue'),
    2: () => import('./annakarenina/blog-2.vue'),
    3: () => import('./annakarenina/blog-3.vue'),
    4: () => import('./annakarenina/blog-4.vue')
  },
  crime: {
    1: () => import('./crime/blog-1.vue'),
    2: () => import('./crime/blog-2.vue'),
    3: () => import('./crime/blog-3.vue'),
    4: () => import('./crime/blog-4.vue')
  },
  prideprejudice: {
    1: () => import('./prideprejudice/blog-1.vue'),
    2: () => import('./prideprejudice/blog-2.vue'),
    3: () => import('./prideprejudice/blog-3.vue'),
    4: () => import('./prideprejudice/blog-4.vue')
  },
  sherlock: {
    1: () => import('./sherlock/blog-1.vue'),
    2: () => import('./sherlock/blog-2.vue'),
    3: () => import('./sherlock/blog-3.vue'),
    4: () => import('./sherlock/blog-4.vue')
  },
  wind: {
    1: () => import('./wind/blog-1.vue'),
    2: () => import('./wind/blog-2.vue'),
    3: () => import('./wind/blog-3.vue'),
    4: () => import('./wind/blog-4.vue')
  }
}

import { mapGetters } from 'vuex'

export default {
  name: 'BlogWrapper',
  props: {
    num: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentBlog: null
    }
  },
  computed: {
    ...mapGetters(['domainConfig'])
  },
  async created () {
    try {
      const type = this.domainConfig?.Type
      console.log('Current type in BlogWrapper:', type)
      if (!type) {
        console.error('domainConfig.Type is undefined')
        this.$router.push('/blogs')
        return
      }

      const number = parseInt(this.num)
      if (blogComponents[type] && blogComponents[type][number]) {
        this.currentBlog = await blogComponents[type][number]()
          .then(m => m.default)
          .catch(err => {
            console.error(`加载博客组件失败: ${type}/blog-${number}.vue`, err)
            this.$router.push('/blogs')
            return null
          })
      } else {
        console.error(`未找到对应的博客组件: ${type}/blog-${number}.vue`)
        this.$router.push('/blogs')
      }
    } catch (error) {
      console.error('加载博客组件时出错:', error)
      this.$router.push('/blogs')
    }
  }
}
</script> 